var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "2" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-list",
                    { attrs: { shaped: "" } },
                    [
                      _c("v-subheader", [_vm._v("Trip Request Sections")]),
                      _c(
                        "v-list-item-group",
                        { attrs: { color: "primary" } },
                        _vm._l(_vm.formSections, function (section, i) {
                          return _c(
                            "v-list-item",
                            {
                              key: i,
                              on: {
                                click: function ($event) {
                                  return _vm.viewSection(i)
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(section)),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "10" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-form",
                    { attrs: { readonly: !_vm.canEditSettings } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "draggable",
                                {
                                  attrs: { disabled: !_vm.canEditSettings },
                                  on: { sort: _vm.handleSort },
                                  model: {
                                    value: _vm.activeSection,
                                    callback: function ($$v) {
                                      _vm.activeSection = $$v
                                    },
                                    expression: "activeSection",
                                  },
                                },
                                [
                                  _c(
                                    "transition-group",
                                    _vm._l(_vm.activeSection, function (field) {
                                      return _c(
                                        "v-row",
                                        { key: field.id },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              "mdi-drag-horizontal-variant"
                                            ),
                                          ]),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  label: "Required",
                                                  disabled:
                                                    field.delete ||
                                                    field.type == 3,
                                                },
                                                model: {
                                                  value: field.required,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      field,
                                                      "required",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "field.required",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "2" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.typeOptions,
                                                  "item-text": "label",
                                                  "item-value": "value",
                                                  label: "Type",
                                                  outlined: "",
                                                  "hide-details": "",
                                                  disabled: field.delete,
                                                },
                                                model: {
                                                  value: field.type,
                                                  callback: function ($$v) {
                                                    _vm.$set(field, "type", $$v)
                                                  },
                                                  expression: "field.type",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "3" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label:
                                                    "Field Name / Question",
                                                  "hide-details": "",
                                                  disabled: field.delete,
                                                },
                                                model: {
                                                  value: field.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(field, "name", $$v)
                                                  },
                                                  expression: "field.name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "2" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.sizeOptions,
                                                  "item-text": "label",
                                                  "item-value": "value",
                                                  label: "Field Size",
                                                  outlined: "",
                                                  "hide-details": "",
                                                  disabled: field.delete,
                                                },
                                                model: {
                                                  value: field.size,
                                                  callback: function ($$v) {
                                                    _vm.$set(field, "size", $$v)
                                                  },
                                                  expression: "field.size",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _vm.canEditSettings
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mx-2",
                                                      attrs: {
                                                        fab: "",
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openTripTypeSelector(
                                                            field
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(" mdi-cog "),
                                                      ]),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _vm.canEditSettings
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mx-2",
                                                      attrs: {
                                                        fab: "",
                                                        dark: "",
                                                        color: "error",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteField(
                                                            field
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { dark: "" } },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.delete
                                                                  ? "mdi-refresh"
                                                                  : "mdi-delete"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _vm.canEditSettings
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mx-2",
                                  attrs: { dark: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addCFF()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-plus")]),
                                  _vm._v("Add Field "),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _vm.canEditSettings
                            ? _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.activeSection &&
                                        _vm.activeSection.length,
                                      expression:
                                        "activeSection && activeSection.length",
                                    },
                                  ],
                                  staticClass: "mx-2",
                                  attrs: { dark: "", color: "success" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.save()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-content-save")]),
                                  _vm._v("Save "),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "800" },
          model: {
            value: _vm.showSettingsDialog,
            callback: function ($$v) {
              _vm.showSettingsDialog = $$v
            },
            expression: "showSettingsDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Custom Field Settings")]),
              _c(
                "v-card-text",
                [
                  _c("v-subheader", [
                    _vm._v(
                      " Display this field for the following Trip Types: "
                    ),
                  ]),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "checkbox",
                          attrs: { cols: "12", md: "3" },
                        },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "All" },
                            on: { change: _vm.handleAllTypes },
                            model: {
                              value: _vm.field.selectAllTypes,
                              callback: function ($$v) {
                                _vm.$set(_vm.field, "selectAllTypes", $$v)
                              },
                              expression: "field.selectAllTypes",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._l(_vm.tripTypes, function (type, i) {
                        return _c(
                          "v-col",
                          {
                            key: i,
                            staticClass: "checkbox",
                            attrs: { cols: "12", md: "3" },
                          },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                value: type.id,
                                label: type.name,
                                multiple: "",
                              },
                              on: { click: _vm.handleTripTypeSelected },
                              model: {
                                value: _vm.field.tripTypeIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.field, "tripTypeIds", $$v)
                                },
                                expression: "field.tripTypeIds",
                              },
                            }),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _c("v-subheader", [
                    _vm._v("Overnight or Out-of-State specific?"),
                  ]),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "", mandatory: "" },
                              model: {
                                value: _vm.field.oos,
                                callback: function ($$v) {
                                  _vm.$set(_vm.field, "oos", $$v)
                                },
                                expression: "field.oos",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Yes", value: true },
                              }),
                              _c("v-radio", {
                                attrs: { label: "No", value: false },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.field.type == 4
                    ? _c("v-subheader", [
                        _vm._v(
                          " Add list items (type in an option then press ENTER): "
                        ),
                      ])
                    : _vm._e(),
                  _vm.field.type == 4
                    ? _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-combobox", {
                                attrs: { items: [], multiple: "", chips: "" },
                                model: {
                                  value: _vm.field.items,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.field, "items", $$v)
                                  },
                                  expression: "field.items",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showSettingsDialog = false
                        },
                      },
                    },
                    [_vm._v(" Confirm ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }